<template>
  <div>
      <div class="flex justify-center">
        <div class="block rounded-lg shadow-lg bg-white max-w-sm text-center">
          <div class="py-3 px-6 border-b border-gray-300">
            Featured
          </div>
          <div class="p-6">
            <h5 class="text-gray-900 text-xl font-medium mb-2">Special title treatment</h5>
            <p class="text-gray-700 text-base mb-4">
              With supporting text below as a natural lead-in to additional content.
            </p>
            <button type="button" class=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Button</button>
          </div>
          <div class="py-3 px-6 border-t border-gray-300 text-gray-600">
            2 days ago
          </div>
        </div>
      </div>    
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mapGetters } from 'vuex'
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { LOGIN, MUESTRA_MENU } from '@/store/actions.type'
import { SET_ERROR, SET_USER,SET_MENU } from '@/store/mutations.type'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    const icons = ref({
        mdiEyeOutline,
        mdiEyeOffOutline,
      })
    
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      email,
      password,
      socialLink,
      icons,
    }
  },
  data () {
    return {
      username: "",
      password: '',
    }
  },  
  components: {
    //MaskedInput,
  },
  computed: {
    ...mapGetters(['menues']),
  },
  methods: {
      async  mostrarMenu() {
          await this.$store.dispatch(MUESTRA_MENU,{}, {}).then(response => {
              this.$store.commit(SET_USER, {
                  token: localStorage.getItem('JWT__refresh__token'),
                  username: response.usuario
                }
              )

              //this.$store.commit(SET_USER, response.usuario)
              this.$store.commit(SET_MENU, response.rest.menues)
                  console.log('Menues mostrar: ', this.menues)      

          }, error => {
              this.$store.commit(SET_ERROR, error.statusText)
          })   
    //    if (this.currentUser.username) {

    //   } 
      },  
      login() {
        let params = { 
            username: this.username,
            password: this.password,
            }
            this.loadingGuardar = true

                this.$store.dispatch(LOGIN, params).then(response => {
                  localStorage.setItem('JWT__access__token', response.rest.user.token)
                  localStorage.setItem('JWT__refresh__token', response.rest.user.token)
                  localStorage.setItem('user', response.rest.user.username)
                  this.mostrarMenu()
                  console.log('Menues: ', this.menues)      
                  this.$store.commit(SET_USER, {
                      token: response.rest.user.token,
                      username: response.rest.user.username,
                      
                    }
                  )
                  this.$notify(
                    {
                      group: "generic",
                      title: "Success",
                      text: "Operación realizada satisfactoriamente",
                    },
                    4000
                  );      

                  //window.location.href = '/pages/register'
                  this.$router.push('/dashboard')
                  //window.location = window.location.origin + window.location.pathname
                }, error => {
                  console.error("Hay un error", error)
                  this.$notify(
                    {
                      group: "error",
                      title: "Error",
                      text: "No se pudo realizar la operación",
                    },
                    4000
                  );                       
                  this.$store.commit(SET_ERROR, 'Error al Ingresar, validar su usuario o clave')
                }).finally(() => (this.loadingGuardar = false))         
                //this.loadingGuardar = false
            setTimeout(() => {
            },3000)         

      } 
  } 
       
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
